* {
  font-family: aktiv-grotesk, aktiv-grotesk-condensed, aktiv-grotesk-extended, aktiv-grotesk-thin, sans-serif !important;
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  outline: 0;
}

* {
margin: 0;
padding: 0;
box-sizing: border-box;
}

html {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  min-width: 100vw;
  max-width: 100vw;
  -webkit-text-size-adjust: none;
  overflow: hidden;
}

#root {
  height: 100%;
  display: flex;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p {
  margin-bottom: 0px;
}

.ant-btn {
  border-radius: 10px !important;
  padding: 4px 20px;
  height: auto;
  box-shadow: 1px solid transparent;
  border: 1px solid transparent;
  box-shadow: rgb(38 42 72 / 8%) 9px 10px 26px 0px !important;
  font-weight: 700;
  color: #a3a1aa;
  background: white;
}
.ant-btn:hover {
  box-shadow: none !important;
  background: #f2f2f3;
  color: #a3a1aa;
}

.ant-btn-default {
  background: white;
  color: #f25277;
  box-shadow: 1px solid transparent;
  border: 1px solid transparent;
  font-weight: 700;
  box-shadow: rgb(38 42 72 / 8%) 9px 10px 26px 0px;
}
.ant-btn-default:hover {
  background: #feeaee;
  color: #f25277;
  outline: 0;
  box-shadow: none;
  border: 1px solid transparent !important;
  font-weight: 700;
}

.text-center {
  text-align: center;
}

.g-recaptcha {
  display: inline-block;
}

.ant-select {
  border-radius: 10px !important;
  background: #f2f2f3 !important;
}
.ant-select-dropdown {
  border-radius: 16px !important;
  padding: 0px;
}
.ant-select-item-option {
  font-size: 16px;
  font-weight: 500;
  padding: 16px;
  color: #a3a1aa;
}
.ant-select-selector {
  border: 0px solid transparent !important;
  border-color: transparent !important;
  background: transparent !important;
  min-width: 50px;
  flex: 1;
  min-height: 50px !important;
  max-height: 50px !important;
  padding: 8px 8px 8px 16px !important;
  height: 48px !important;

  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  border-radius: 10px !important;
}
.ant-select-selector:focus,
.ant-select-selector:hover {
  border: 0px solid transparent !important;
  border-color: transparent;
  background: transparent !important;
  border-color: transparent;
  outline: 0;
  border-radius: 10px !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector  {
  border-radius: 10px !important;
  background-color: #f2f2f3 !important;
}

.ant-select-selection-item {
  font-size: 16px;
  font-weight: 500;
  color: #a3a1aa;
  padding-top: 2px !important;
  padding-left: 2px !important;
}

.ant-input-affix-wrapper {
  box-shadow: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 12px !important;
  border: 1px solid transparent;
}
.ant-input {
  border: 0px solid transparent !important;
  border-color: transparent !important;
  background: transparent !important;
  min-width: 50px;
  flex: 1;
  min-height: 40px !important;
  max-height: 48px !important;
  padding: 8px 8px 8px 8px !important;
  height: 48px !important;

  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 20px !important;
}
.ant-input:focus,
.ant-input:hover {
  box-shadow: none;
  outline: 0;
}
.ant-input-prefix {
  margin-left: 4px !important;
  /* margin-right: 4px !important; */
}
.ant-input-suffix  {
  margin-right: 10px !important;
}

.ant-form-item {
  margin-bottom: 0px !important;
}

.ant-form-item-explain-connected {
  display: none !important;
}

textarea.ant-input {
  resize: none;
  padding: 20px !important;
  font-size: 16px !important;
}

input[type='checkbox'] {
  -ms-transform: scale(1); /* IE */
  -moz-transform: scale(1); /* FF */
  -webkit-transform: scale(1); /* Safari and Chrome */
  -o-transform: scale(1); /* Opera */
  transform: scale(1);
  padding: 10px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #fa8072;
  border-color: #fa8072;
}

.item {
  cursor: pointer;
  transition: color 0.3s ease-out;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-button {
  display: none;
}

.simple-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
  padding: 40px;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  /* background: grey; */
  margin-bottom: 30px;
}

.ant-tabs-nav-list {
  flex: 1;
}

.ant-tabs-nav-wrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex: 1;
  align-items: center;
  padding: 0px;
  margin: 0px;
}

.ant-tabs-tab {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: 4px solid #a3a1aa;
}

.ant-tabs-tab-btn[aria-selected='true'] {
  color: #2e3a59 !important;
  font-weight: 700 !important;
}
.ant-tabs-tab-btn[aria-selected='false'] {
  color: #a3a1aa !important;
}
.ant-tabs-ink-bar {
  background-color: #47d7e4;
  height: 4px !important;
  border-radius: 4px;
}
/*onHover set background to 47D8E5*/
.ant-modal-close-x:hover {
  color: black !important;
}

.Toastify {
  zoom: 120% !important;
  z-index: 9999999;
}

.Toastify__toast {
  border-radius: 14px !important;
}

.Toastify__toast-body {
  font-size: 16px;
  color: #2e3a59;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #2aad76;
}

.ant-checkbox,
.ant-checkbox-input,
.ant-checkbox-inner {
  border-radius: 16px;
}

.ant-checkbox:hover,
.ant-checkbox-input:hover,
.ant-checkbox-inner:hover {
  border-color: transparent;
  outline: none;
  background-color: red;
}

.ant-checkbox-checked::after {
  border-color: transparent;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #2aad76;
}

.ant-checkbox-inner::after {
  visibility: hidden;
  width: 0px;
  height: 0px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #2aad76;
  border-color: #2aad76;
}

.ant-radio-inner::after {
  background-color: #2aad76;
}

.ant-radio-checked::after,
.ant-radio-inner::after,
.ant-radio-inner:hover {
  border-color: #2aad76;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #2aad76;
}

.ant-form input[type='file']:focus,
.ant-form input[type='radio']:focus,
.ant-form input[type='checkbox']:focus {
  outline: none;
}

.ant-picker:hover,
.ant-picker-focused {
  box-shadow: none;
  border-color: transparent;
}

.ant-picker {
  font-size: 16px !important;
  padding-left: 0;
}

.ant-picker-input > input {
  font-family: aktiv-grotesk, aktiv-grotesk-condensed, aktiv-grotesk-extended, aktiv-grotesk-thin, sans-serif !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  cursor: pointer;
}

.ant-picker-input > input:hover {
  font-weight: 500;
}

.ant-picker-panel-container .ant-picker-panel {
  font-size: 16px !important;
}

.ant-btn-primary,
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  font-weight: 500;
  border-color: transparent;
  text-shadow: none;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  border-color: transparent;
  background-color: #f5f5f5 !important;
}

.ant-picker-now-btn {
  color: #2e3a59;
}

.ant-picker-now-btn:hover {
  color: #2e3a59;
}

.ant-picker-time-panel-column {
  overflow-y: auto !important;
}

.ant-picker-panel .ant-picker-footer {
  padding: 10px;
}

.ant-picker-datetime-panel {
  padding: 10px;
  /* width: 400px; */
}
.ant-picker-dropdown {
  scale: 0.8;
}
.custom-calendar-full .custom-panel .custom-body th,
.custom-calendar-full .custom-panel .custom-body td {
  width: 200px;
}

.ant-picker-ok > .ant-btn {
  font-weight: bold;
}

.ant-picker-month-btn {
  padding: 0px 20px !important;
  border-radius: 10px;
  text-transform: capitalize;
}

.ant-picker-cell-inner {
  text-transform: capitalize;
}

.ant-picker-year-btn {
  padding: 0px 20px !important;
  border-radius: 10px;
}

.ant-picker-cell-disabled::before {
  border-radius: 6px !important;
}

.ant-picker-cell-inner {
  width: unset !important;
}

.ant-picker-header-view button:hover {
  color: #2e3a59 !important;
}

.ant-picker-cell .ant-picker-cell-inner {
  line-height: unset;
}

.ant-picker-date-panel {
  width: fit-content;
}

#datePiker {
  scale: 0.8;
}

.ant-picker-panel-container .ant-picker-panel .ant-picker-content,
.ant-picker-panel-container .ant-picker-panel table {
  border-spacing: 5px;
  border-collapse: separate;
}

.ant-picker-header-view {
  padding: 10px 0px;
}

.ant-btn-primary {
  box-shadow: none;
}

.crello-closeBtn {
  visibility: hidden;
}

.crello-loader-in-popup {
  visibility: hidden;
}

.crello-iframe-in-popup {
  visibility: hidden;
}

.crello-overlayFrame {
  visibility: hidden;
}

.ant-modal {
  z-index: 999999;
}

.ant-slide-up-appear,
.ant-slide-up-enter,
.ant-slide-up-leave {
  animation-duration: 0s !important;
}

.editor-modal.ant-modal-content {
  background-color: transparent !important;
  box-shadow: none !important;
}

.ant-popover-message {
  font-size: 16px;
}

.ant-select-selection-placeholder {
  opacity: 0.6 !important;
}

.ant-picker-preset .ant-tag.ant-tag-blue {
  display: flex !important;
  height: 28px !important;
  padding: 4px 8px !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 4px !important;
  flex: 1 0 0 !important;
  border-radius: 8px !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  background-color: #f2f2f3 !important;
  border-color: grey !important;
  color: black !important;
}

.ant-picker-ranges {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 16px !important;
  gap: 8px !important;
}

.blurred {
  filter: blur(4px);
}

input::placeholder {
  font-weight: 700;
  font-size: 14px;
  color: #a3a1aa;
}

#allFolderContainer {
  /* background-color: royalblue!important; */
  z-index: 99999;
}
#allFolderContainer::-webkit-scrollbar {
  width: 0px;
  opacity: 0;
}
#secondaryFcontainer {
  /* position: absolute; */
  opacity: 0;
  max-height: 10vh !important;
  z-index: 10;
  /* background-color: #2aad76!important; */
}

#secondaryFcontainer::-webkit-scrollbar {
  width: 0px;
  opacity: 0;
}

#pressHere {
  opacity: 0;
  position: absolute;
}

.ImageRender {
  z-index: -1;
  opacity: 0;
}

.ImgEmpty {
  transform: translate(0, -1000px);
  opacity: 1;
  border: 1px solid #2aad76;
}

/* 
.realInfo{

} */

.swiper-pagination-bullet {
  width: 4px !important;
  height: 4px !important;
  border-radius: 4px !important;
  margin: 0px !important;
  opacity: 1 !important;

  transition: 300ms ease-in-out !important;
}

.swiper-pagination-bullet-active {
  width: 24px !important;
}

.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
  margin-top: 4px !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 8px !important;
}

/* .mySwiper_Topics .swiper-wrapper {
  transition: transform 500ms ease-in-out !important;
} */

/* --- */
.ButtonNext_Topics {
  margin-top: 5px !important;
  border: 1px solid transparent !important;
  transition: 250ms ease-in-out !important;
  cursor: pointer !important;
  padding: 5px !important;
  border-radius: 8px !important;

  background: none !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.ButtonNext_Topics:hover {
  box-shadow: 0.4824959933757782px 0.5522457957267761px 1.059259295463562px 0px rgba(38, 42, 72, 0.02),
    2.2808902263641357px 2.610616445541382px 4.140740871429443px 0px rgba(38, 42, 72, 0.04),
    5.921541690826416px 6.777561664581299px 13px 0px rgba(38, 42, 72, 0.08) !important;
}

.ButtonNext_Topics svg {
  width: 30px !important;
  height: 30px !important;
  transition: 250ms ease-in-out !important;
}

/* --- */

.ButtonPrev_Topics {
  margin-top: 5px !important;
  border: 1px solid transparent !important;
  transition: 250ms ease-in-out !important;
  cursor: pointer !important;
  padding: 5px !important;
  border-radius: 8px !important;

  background: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ButtonPrev_Topics:hover {
  box-shadow: 0.4824959933757782px 0.5522457957267761px 1.059259295463562px 0px rgba(38, 42, 72, 0.02),
    2.2808902263641357px 2.610616445541382px 4.140740871429443px 0px rgba(38, 42, 72, 0.04),
    5.921541690826416px 6.777561664581299px 13px 0px rgba(38, 42, 72, 0.08) !important;
}

.ButtonPrev_Topics svg {
  width: 30px !important;
  height: 30px !important;
  transition: 250ms ease-in-out !important;
}

/* --- */
.ButtonNext_Boards {
  border: 1px solid transparent !important;
  transition: 250ms ease-in-out !important;
  cursor: pointer !important;
  padding: 5px !important;
  border-radius: 8px !important;
  background: none !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.ButtonNext_Boards:hover {
  box-shadow: 0.4824959933757782px 0.5522457957267761px 1.059259295463562px 0px rgba(38, 42, 72, 0.02),
    2.2808902263641357px 2.610616445541382px 4.140740871429443px 0px rgba(38, 42, 72, 0.04),
    5.921541690826416px 6.777561664581299px 13px 0px rgba(38, 42, 72, 0.08) !important;
}

.ButtonNext_Boards svg {
  width: 30px !important;
  height: 30px !important;
  transition: 250ms ease-in-out !important;
}

/* --- */

.ButtonPrev_Boards {
  border: 1px solid transparent !important;
  transition: 250ms ease-in-out !important;
  cursor: pointer !important;
  padding: 5px !important;
  border-radius: 8px !important;
  background: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ButtonPrev_Boards:hover {
  box-shadow: 0.4824959933757782px 0.5522457957267761px 1.059259295463562px 0px rgba(38, 42, 72, 0.02),
    2.2808902263641357px 2.610616445541382px 4.140740871429443px 0px rgba(38, 42, 72, 0.04),
    5.921541690826416px 6.777561664581299px 13px 0px rgba(38, 42, 72, 0.08) !important;
}

.ButtonPrev_Boards svg {
  width: 30px !important;
  height: 30px !important;
  transition: 250ms ease-in-out !important;
}

/*
Temporary solution
*/
.ant-picker-date-panel .ant-picker-content th {
  width: 36px !important;
  overflow: hidden !important;
  text-overflow: clip !important;
  max-width: 36px !important;
}

/* Animazione skeleton */
@keyframes loading-skeleton {
  to {
    background-position: -100% 0;
  }
}

/* ----------- REACT-GRID-LAYOUT */
.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}

.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top, width, height;
}

.react-grid-item img {
  pointer-events: none;
  user-select: none;
}

.react-grid-item.cssTransforms {
  transition-property: transform, width, height;
}

.react-grid-item.resizing {
  transition: none;
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.dropping {
  visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
  background: #d8dce0;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  border-radius: 14px;
}

/* START MIU DATE RANGE PICKER */
/* ------------ container of range picker ------------ */
div[class*='MuiPaper-root-MuiPickersPopper-paper'] {
  border-radius: 14px !important;
}

div[class*='MuiPickersLayout-root'] {
  align-items: center;
  justify-items: center;
  gap: 16px;
  padding: 24px 32px;
  border-radius: 14px;
}

/*  posizione shortcuts (pulsanti della selezioni giorni) */
ul[class*='MuiPickersLayout-shortcuts'] {
  grid-row: 1 !important;
  display: flex !important;
}

ul[class*='MuiList-root'] {
  max-width: none !important;
}

li[class*='MuiListItem-root'] {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

/*  posizione calendario */
div[class*='MuiPickersLayout-contentWrapper'] {
  grid-column: 1 !important;
  grid-row: 2 !important;
  border-radius: 14px;
  border: 1px solid #d8dce0;
}

/* stile della tooltip date */
input[class*='MuiInputBase-input'] {
  padding: 0px !important;
  height: auto !important;
  position: relative;
  top: 3px;
}

fieldset[class*='MuiOutlinedInput-notchedOutline'] {
  border: none !important;
}

/* ------------ stile calendario singolo "static" ------------ */
div[class*='MuiPickersLayout-root'] {
  display: flex !important;
  flex-direction: column !important;
}

/* selezione dei dati conferma ed annulla mui */

/* ! Da controllare */
/* div[class*='MuiPickersLayout-actionBar'] {
  display: none !important;
} */

/* scritta "seleziona una data" */
span[class*='MuiTypography-overline'] {
  display: none !important;
}

div[class*='MuiPickersToolbar-content'] {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

div[class*='MuiPickersLayout-toolbar'] {
  padding: 0px !important;
}

/* ------------ */
.react-grid-item.grid-item {
  cursor: unset !important;
}
