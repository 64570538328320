/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.custom-calendar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  background: transparent;
}
.custom-calendar-header {
  display: flex;
  justify-content: flex-end;
  padding: 12px 0;
}
.custom-calendar-header .custom-calendar-year-select {
  min-width: 80px;
}
.custom-calendar-header .custom-calendar-month-select {
  min-width: 70px;
  margin-left: 8px;
}
.custom-calendar-header .custom-calendar-mode-switch {
  margin-left: 8px;
}
.custom-calendar .custom-panel {
  background: '#fff';
  border: 0;
  border-top: 1px solid #f0f0f0;
  border-radius: 0;
  max-height: 0;
}
.custom-calendar .custom-panel .custom-month-panel,
.custom-calendar .custom-panel .custom-date-panel {
  width: auto;
}
.custom-calendar .custom-panel .custom-body {
  padding: 8px 0;
}
.custom-calendar .custom-panel .custom-content {
  width: 100%;
}
.custom-calendar-mini {
  border-radius: 2px;
}
.custom-calendar-mini .custom-calendar-header {
  padding-right: 8px;
  padding-left: 8px;
}
.custom-calendar-mini .custom-panel {
  border-radius: 0 0 2px 2px;
}
.custom-calendar-mini .custom-content {
  height: 256px;
}
.custom-calendar-mini .custom-content th {
  height: auto;
  padding: 0;
  line-height: 18px;
}
.custom-calendar-full .custom-panel {
  display: block;
  width: 100%;
  text-align: right;
  background: transparent;
  border: 0;
}
.custom-calendar-full .custom-panel .custom-body th,
.custom-calendar-full .custom-panel .custom-body td {
  padding: 0;
}
.custom-calendar-full .custom-panel .custom-body th {
  height: auto;

  font-family: aktiv-grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  padding-left: 8px;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-color: #a3a1aa;
  color: #a3a1aa;
}
.custom-calendar-full .custom-panel .custom-cell::before {
  display: none;
}
.custom-calendar-full .custom-panel .custom-cell:hover .custom-calendar-date {
  background: #f5f5f5;
}
.custom-calendar-full .custom-panel .custom-cell .custom-calendar-date-today::before {
  display: none;
}
.custom-calendar-full .custom-panel .custom-cell-selected .custom-calendar-date,
.custom-calendar-full .custom-panel .custom-cell-selected:hover .custom-calendar-date,
.custom-calendar-full .custom-panel .custom-cell-selected .custom-calendar-date-today,
.custom-calendar-full .custom-panel .custom-cell-selected:hover .custom-calendar-date-today {
  background: #e6f7ff;
}
.custom-calendar-full .custom-panel .custom-cell-selected .custom-calendar-date .custom-calendar-date-value,
.custom-calendar-full .custom-panel .custom-cell-selected:hover .custom-calendar-date .custom-calendar-date-value,
.custom-calendar-full .custom-panel .custom-cell-selected .custom-calendar-date-today .custom-calendar-date-value,
.custom-calendar-full
  .custom-panel
  .custom-cell-selected:hover
  .custom-calendar-date-today
  .custom-calendar-date-value {
  color: #1890ff;
}
.custom-calendar-full .custom-panel .custom-calendar-date {
  display: block;
  width: auto;
  height: auto;
  margin: 0 4px;
  padding: 4px 8px 0;
  border: 0;
  border-top: 2px solid #f0f0f0;
  border-radius: 0;
  transition: background 0.3s;
}

.custom-calendar-full .custom-panel .custom-calendar-date-value {
  line-height: 24px;
  transition: color 0.3s;
}
.custom-calendar-full .custom-panel .custom-calendar-date-content {
  position: static;
  width: auto;
  height: 86px;
  overflow-y: auto;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  text-align: left;
}
.custom-calendar-full .custom-panel .custom-calendar-date-today {
  border-color: #1890ff;
}
.custom-calendar-full .custom-panel .custom-calendar-date-today .custom-calendar-date-value {
  color: rgba(0, 0, 0, 0.85);
}
@media only screen and (max-width: 480px) {
  .custom-calendar-header {
    display: block;
  }
  .custom-calendar-header .custom-calendar-year-select {
    width: 50%;
  }
  .custom-calendar-header .custom-calendar-month-select {
    width: calc(50% - 8px);
  }
  .custom-calendar-header .custom-calendar-mode-switch {
    width: 100%;
    margin-top: 8px;
    margin-left: 0;
  }
  .custom-calendar-header .custom-calendar-mode-switch > label {
    width: 50%;
    text-align: center;
  }
}
.custom-calendar-rtl {
  direction: rtl;
}
.custom-calendar-rtl .custom-calendar-header .custom-calendar-month-select {
  margin-right: 8px;
  margin-left: 0;
}
.custom-calendar-rtl .custom-calendar-header .custom-calendar-mode-switch {
  margin-right: 8px;
  margin-left: 0;
}
.custom-calendar-rtl.custom-calendar-full .custom-panel {
  text-align: left;
}
.custom-calendar-rtl.custom-calendar-full .custom-panel .custom-body th {
  padding: 0 0 5px 12px;
}
.custom-calendar-rtl.custom-calendar-full .custom-panel .custom-calendar-date-content {
  text-align: right;
}

.wrapoff {
  width: 200px;
  /* border: 2px solid blue; */
  white-space: nowrap;
  overflow: hidden;
  position: relative;
}

